import React, { useState, useReducer, useCallback, useEffect } from "react";
import presignedUrl from "js/config/s3Config";
import { animate } from "motion";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

function ImageViewer({ imageObj, image, showPopup = true, t }) {
  const [show, setShow] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    (async () => {
      if (imageObj) setSource(await presignedUrl(`${imageObj.uuid}.${imageObj.suffix}`, "uploads/"));
      else if (image) setSource(image);
    })();
  }, [imageObj, image]);

  const closeOverlay = () => {
    setShow(false);
  };
  const closeOverlayOnTargetClick = (e) => {
    if (e.target === e.currentTarget) setShow(false);
  };
  const openOverlay = () => {
    setShow(true);
  };

  const ImageOverlay = () => {
    const [isMounted] = useReducer((p) => !p, true);
    const handleMount = useCallback((e) => {
      const overlay = e;
      if (overlay) {
        const scroll = window.scrollY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${scroll}px`;
        overlay.style.top = `${overlay.offsetTop + scroll}px`;
        animate(".image-overlay", {
          opacity: [0, 1],
        });
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    }, []);

    return (
      <>
        {isMounted && (
          <div ref={handleMount} className="image-overlay" onClick={closeOverlayOnTargetClick}>
            <div className="image-container">
              <img className="image" src={source} alt="img" />
              <div onClick={closeOverlay} className="cross-button" />
            </div>
          </div>
        )}
      </>
    );
  };

  const ImageView = () => (
    <div className="image-viewer">
      {source && <img className="thumbnail" src={source} alt="img" width="100%" height="100%" />}
      {showPopup && source && (
        <div onClick={openOverlay} className="overlay">
          <span className="view-button">{t("image.view")}</span>
        </div>
      )}
    </div>
  );

  return (
    <>
      {ImageView()}
      {show && <ImageOverlay />}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
})(withTranslation()(ImageViewer));

