import React from "react";
import ImageViewer from "./ImageViewer";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

function ImageUpload({ image, setImage, t }) {
  const imageChangeHandler = (e) => {
    setImage(e.target.files[0]);
    e.target.value = null;
  };
  return (
    <div className="image-upload">
      <div className="image-div">
        <ImageViewer image={image} />
      </div>
      <label className="upload-button">
        <input type="file" accept=".jpg, .jpeg, .png, .svg" onChange={imageChangeHandler} />
        {image ? t("button.change") : t("button.select")}
      </label>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
})(withTranslation()(ImageUpload));

