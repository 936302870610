import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getUser } from "js/redux/actions/user";
import Dropdown from "./Dropdown";
import { useNavigate } from "react-router-dom";
import { logOut } from "js/redux/actions/auth";
import LoaderDiv from "./LoaderDiv";

function ProfileDropdown({ getUser, logOut, userData, t }) {
  let navigate = useNavigate();
  const [userName, setUserName] = useState(null);
  const [loading, setLoading] = useState(true);

  const name = (strings, ...values) => {
    return values.filter((value) => value !== null).join(" ");
  };
  useEffect(() => {
    (async () => {
      await getUser();
      setLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData) setUserName(name`${userData.firstname} ${userData.lastname}`);
  }, [userData]);

  const dropdownLinks = [
    <button
      className="link"
      key="logout-link"
      onClick={() => {
        logOut();
        navigate('/login');
      }}>
      {t("login.logout")}
    </button>,
  ];

  return (
    <div className="profile-dropdown">
      {!loading ? (
        <Dropdown options={dropdownLinks} userName={userName} />
      ) : (
        <LoaderDiv />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
  };
}

export default connect(mapStateToProps, { getUser, logOut })(withTranslation()(ProfileDropdown));
