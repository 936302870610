import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

import { configureStore } from "@reduxjs/toolkit";
import {
  axiosAuthMiddleware,
  axiosErrorHandler,
} from "js/middleware/axios-middleware";
import { Provider } from "react-redux";
import reducers from "js/redux/reducers";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import "scss/main.scss";
import "react-toastify/dist/ReactToastify.css";

import Dashboard from "js/views/pages/Dashboard";
import Summary from "js/views/pages/Summary";
import Login from "js/views/pages/Login";
import Error from "js/views/pages/Error";

import PageNotFound from "js/views/pages/PageNotFound";
// import ProtectedRoute from "js/views/containers/ProtectedRoute";
import Consultations from "js/views/pages/Consultations";
// import ChangePassword from "js/views/pages/ChangePassword";
import BankIdReturn from "js/views/components/BankIdReturn";
import LoginEmail from "js/views/pages/LoginEmail";
import SignicatReturn from "js/views/pages/SignicatReturn";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  blacklist: [],
};

const pReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(axiosAuthMiddleware, axiosErrorHandler),
  reducer: pReducer,
});

const persistor = persistStore(store);

//remove me comment

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading persistor={persistor}>
        <I18nextProvider i18n={i18n}></I18nextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signicat-return" element={<SignicatReturn />} />
            <Route path="/login-email" element={<LoginEmail />} />
            <Route path="/dashboard" element={<Dashboard />}>
              <Route index path="consultations" element={<Consultations />} />
              <Route path="summary/:consultationId" element={<Summary />} />
            </Route>
            <Route path="/bankid-return" element={<BankIdReturn />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
