import { followup, getDiagnosisByUUID } from "js/redux/actions/diagnoses";
import React, { useState } from "react";
import DDTextarea from "./DDTextarea";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CaseReport from "./CaseReport";
import { getDateFormat } from "js/HelperMethods";

function AskQueries({ followup, getDiagnosisByUUID, diagnoses, referrals, t, i18n }) {
  const { consultationId } = useParams();
  const [query, setQuery] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await followup({
      diagnosis: diagnoses[diagnoses.length - 1].uuid,
      reason: query,
      uuid: consultationId,
    });
    await getDiagnosisByUUID(consultationId);
    e.target.reset();
  };

  const secondOpinion = (diagnoses) => {
    if (diagnoses[diagnoses.length - 1].second_opinion_reason !== undefined && diagnoses[diagnoses.length - 1].second_opinion_reason !== null)
      return { text: diagnoses[diagnoses.length - 1].second_opinion_reason, time: diagnoses[diagnoses.length - 1].updated_at };
    else return false;
  };

  const docReply = (diagnoses) => {
    if (diagnoses.length > 1) return diagnoses[0];
    else return false;
  };

  const Convo = ({ diagnoses }) => {
    const opinion = secondOpinion(diagnoses);
    const diag = docReply(diagnoses);
    return opinion ? (
      <div className="second-opinion-convo">
        <div className="line" />
        <span className="patient-convo convo">
          <span className="text">{opinion.text}</span>
          <span className="card-timestamp">{getDateFormat(opinion.time, i18n.language, true)}</span>
        </span>
        {diag ? (
          <div className="doctor-convo convo">
            <CaseReport showHeader={false} {...diag} referrals={referrals.filter((referral) => referral.diagnosis === diag.uuid)} />
          </div>
        ) : (
          <span className="doctor-convo convo muted-convo">{t("query.will_get_back")}</span>
        )}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <form className="ask-queries" onSubmit={handleSubmit}>
      {/* <span className="text-header">{t("query.title")}</span> */}
      <Convo diagnoses={diagnoses} />
      {/* {!secondOpinion(diagnoses) && (
        <>
          <DDTextarea
            className="dd-outline-textarea"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            required
          />
          <button disabled={query === null || query === ""} className="submit-button" type="submit">
            {t("query.button")}
          </button>
        </>
      )} */}
    </form>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  followup,
  getDiagnosisByUUID,
})(withTranslation()(AskQueries));
