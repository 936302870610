import moment from "moment";

const providerForBankId = {
  nb: "no-bankid",
  sv: "se-bankid",
};

const urlProvider = {
  no: "http://snapmed.no",
  se: "http://snapmed.se",
};

export function getProviderForBankId(locale) {
  return providerForBankId[locale] || null;
}
export function getRedirectUrl(region) {
  return urlProvider[region] || null;
}

export const getDateFormat = (date, locale, includeTime = false) => {
  let dateFormat = "Do MMM, YYYY";
  if (locale === "nb") {
    dateFormat = "DD.MM.YY";
  } else if (locale === "sv") {
    dateFormat = "YYYY-MM-DD";
  }
  includeTime && (dateFormat += " HH:mm");
  return moment(date).format(dateFormat);
};
