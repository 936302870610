import React, { useState, useReducer, useCallback } from "react";

function UseModal() {
  const [show, setShow] = useState(false);
  const hideModal = () => {
    setShow(false);
  };
  const showModal = () => {
    setShow(true);
  };

  function Modal({ children }) {
    const [isMounted] = useReducer((p) => !p, true);
    const handleMount = useCallback((e) => {
      const overlay = e;
      if (overlay) {
        const scroll = window.scrollY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${scroll}px`;
        overlay.style.top = `${overlay.offsetTop + scroll}px`;
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    }, []);
    const closeOverlayOnTargetClick = (e) => {
      if (e.target === e.currentTarget) hideModal();
    };
    return show && isMounted ? (
      <div ref={handleMount} className="overlay-div" onClick={closeOverlayOnTargetClick}>
        {children}
      </div>
    ) : (
      <></>
    );
  }

  return [Modal, showModal, hideModal];
}

export default UseModal;
