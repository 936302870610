import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

const LoginPhone = (props) => {
  let navigate = useNavigate();

  const handleButtonClicked = async () => {
    navigate('/login-email');
  };

  const { t } = props;

  return (
    <>
      <button onClick={() => handleButtonClicked()} className="dd-button-light">
        {t("bankid.login_email")}
      </button>
    </>
  );
};

function mapStateToProps(state) {
  return {
    region: state.auth.region,
    locale: state.auth.locale,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(LoginPhone));
