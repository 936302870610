import React, { useState } from "react";

function DDButton({ className, onClick, children, disabled, ...rest }) {
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const getClassName = () => {
    let styleClass = ["dd-button-rounded"];
    className && styleClass.push(className);
    return styleClass.join(" ");
  };
  const handleOnClick = async () => {
    setBtnDisabled(true);
    setLoading(true);
    await onClick();
    setLoading(false);
    setBtnDisabled(false);
  };
  const btnContent = () => {
    if (loading) return "loading";
    else return children;
  };
  return (
    <button className={getClassName()} onClick={handleOnClick} disabled={disabled || btnDisabled}>
      {btnContent()}
    </button>
  );
}

export default DDButton;
