import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { emailLogin, otpVerify } from "js/redux/actions/auth";
import AuthCode from "react-auth-code-input";

import { set_initial_state } from "js/redux/actions/auth";
import LoaderDiv from "../components/LoaderDiv";
import { animate } from "motion";

function LoginEmail({ t, region, emailLogin, setInitialState, otpVerify, loginSuccess }) {
  const [phoneNo, setPhoneNo] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    setInitialState();
  });

  useEffect(() => {
    setError(null);
  }, [password, phoneNo, otp]);

  useEffect(() => {
    animate(".login-email *", { opacity: [0, 1, "none"] }, { duration: 0.8, easing: "ease-out" });
  }, [loginSuccess]);

  const handleSubmit = async () => {
    setLoading(true);
    // function setSubmitError(){}
    await emailLogin(
      {
        email: "",
        password: password,
        phonenumber: phoneNo,
        region: region,
      },
      () => {},
      () => {
        setError(t("errors.incorrect_email_password"));
      }
    );
    setLoading(false);
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    otpVerify(
      otp,
      () => {
        navigate('/dashboard/consultations');
      },
      () => {
        setError("OTP incorrect");
      }
    );
    setLoading(false);
  };

  const emailPassword = () => (
    <>
      <div className="input-div">
        <label>{t("login.phone_number")}</label>
        <PhoneInput placeholder={t("login.enter_phone_number")} value={phoneNo} onChange={setPhoneNo} />
      </div>
      <div className="input-div">
        <label>{t("login.password")}</label>
        <input onChange={(e) => setPassword(e.target.value)} className="password-input" type="password" />
      </div>
      <button onClick={handleSubmit} disabled={!phoneNo || !password} className="proceed-button">
        {loading ? <LoaderDiv /> : t("login.proceed")}
      </button>
    </>
  );

  const otpDiv = () => (
    <>
      <div className="input-div">
        <label>{t("login.otp_verify")}</label>
        <AuthCode allowedCharacters="numeric" containerClassName="otp-container" inputClassName="otp-input" length={4} onChange={setOtp} />
      </div>
      <button onClick={handleOtpSubmit} disabled={!otp || otp.length !== 4} className="proceed-button">
        {loading ? <LoaderDiv /> : t("login.proceed")}
      </button>
    </>
  );

  return (
    <>
      <Header />
      <div className="login-email">
        <div className="login-email-container">
          <div className="login-header">
            <h1>{t("login.heading")}</h1>
            <h3>{t("login.subheading")}</h3>
          </div>
          {loginSuccess ? otpDiv() : emailPassword()}
          {error !== null && (
            <div className="error-div">
              <span className="error-text">{error}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    region: state.auth.region,
    loginSuccess: state.auth.loginSuccess,
  };
}

export default connect(mapStateToProps, {
  emailLogin,
  setInitialState: set_initial_state,
  otpVerify,
})(withTranslation()(LoginEmail));
