import React, { useRef, useEffect, useState } from "react";
import { animate } from "motion";

function Collapse({ children, headerShowComponent, headerHideComponent, defaultShow = false }) {
  const [show, setShow] = useState(true);
  const [id, setId] = useState(null);
  const divRef = useRef();

  useEffect(() => {
    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    setId(uniqueId);
    setShow(defaultShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (divRef.current) {
      if (show) {
        divRef.current.style.display = "block";
        animate(`#${id} > .content`, { height: ["0%", "100%"], opacity: [0, 1] }, { duration: 0.2, easing: "ease-out" });
        animate(`#${id} #collapse-header`, { opacity: [0, 1] }, { duration: 0.5, easing: "ease-out" });
        animate(`#${id} .collapse-button`, { transform: "rotate(180deg)" }, { duration: 0.2, easing: "ease-out" });
      } else {
        animate(`#${id} .collapse-button`, { transform: "rotate(0deg)" }, { duration: 0.2, easing: "ease-out" });
        animate(`#${id} #collapse-header`, { opacity: [0, 1] }, { duration: 0.5, easing: "ease-out" });
        animate(`#${id} > .content`, { height: ["100%", "0%"], opacity: [1, 0] }, { duration: 0.2, easing: "ease-out" }).finished.then(() => {
          divRef.current.style.display = "none";
        });
      }
    }
  }, [show, id]);

  return (
    <div id={id} className="collapse">
      <div
        onClick={() => {
          setShow((show) => !show);
        }}
        className="collapse-header">
        <div id="collapse-header">{show ? headerShowComponent : headerHideComponent}</div>
        <button className="collapse-button"></button>
      </div>
      <div ref={divRef} className="content">
        {children}
      </div>
    </div>
  );
}

export default Collapse;
