import classNames from "classnames";

export const spinnerSizes = {
  xs: "20px",
  sm: "24px",
  md: "32px",
  lg: "48px",
  xl: "64px",
};
const LoaderDiv = ({ theme, size = "lg", lightBg, isPride }) => (
  <div className="loader-div">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 200 200" width={spinnerSizes[size]} data-theme={theme} className="bg-transparent">
      {isPride ? (
        <defs>
          <linearGradient id="gradient-pride" x1="0" y1="1" x2="1" y2="0">
            <stop offset="0%" stopColor="#FE0000" />
            <stop offset="16.66%" stopColor="#FE0000" />
            <stop offset="16.66%" stopColor="#FD8C00" />
            <stop offset="33.32%" stopColor="#FD8C00" />
            <stop offset="33.32%" stopColor="#FFE500" />
            <stop offset="49.98%" stopColor="#FFE500" />
            <stop offset="49.98%" stopColor="#119F0B" />
            <stop offset="66.64%" stopColor="#119F0B" />
            <stop offset="66.64%" stopColor="#0644B3" />
            <stop offset="83.3%" stopColor="#0644B3" />
            <stop offset="83.3%" stopColor="#c22edc" />
            <stop offset="100%" stopColor="#c22edc" />
          </linearGradient>
        </defs>
      ) : null}
      <path
        d="M9.5 82.0002C9.5 63 20.5 53 34.5 53C42 53 49.2837 53 57.5 53C57.5 46.844 57.5 35 57.5 29.5C57.5 -3.50001 110 -2 110 29.5C110 36.5 110 46.3529 110 52.5"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        className={classNames(
          {
            "stroke-primary": lightBg,
            "stroke-off-white": !lightBg,
          },
          "spinner-path"
        )}
        stroke="url(#gradient-pride)"
      />

      <path
        d="M158.5 77C158.5 96.0002 147.5 106 133.5 106C126 106 118.716 106 110.5 106C110.5 112.156 110.5 124 110.5 129.5C110.5 162.5 58 161 58 129.5C58 122.5 58 112.647 58 106.5"
        strokeWidth={isPride ? "15" : "10"}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        className={classNames({ "stroke-secondary": !isPride }, "spinner-path")}
        stroke={"url(#gradient-pride)"}
      />
    </svg>
  </div>
);

export default LoaderDiv;
