import { GET_DIAGNOSES, GET_CONSULTATION, UPDATE_DOCTORS_DATA } from "js/redux/type.js";

const initialState = {
  consultations: [],
  selectedConsultation: {},
  doctors: {},
};

const diagnoses = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIAGNOSES:
      return {
        ...state,
        consultations: action.payload.examinations,
      };
    case GET_CONSULTATION:
      return {
        ...state,
        selectedConsultation: action.payload,
      };
    case UPDATE_DOCTORS_DATA:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default diagnoses;
