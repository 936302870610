import { axiosAuth } from "js/config/axios-instance";
import { logOut } from "services/auth-service";

export function axiosAuthMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    axiosAuth.interceptors.request.use(
      function (config) {
        config.headers.token = getState().auth.token;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    next(action);
  };
}

export function axiosErrorHandler(store) {
  return (next) => (action) => {
    axiosAuth.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const err = error.response.data.error;
        if (err === "Provided token is expired." || err === "Token not provided.") logOut();
        return Promise.reject(error);
      }
    );
    next(action);
  };
}
