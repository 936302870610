import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getProviderForBankId } from "js/HelperMethods";
import PhoneLogin from "js/views/components/PhoneLogin";
import { init_bankid, login_bankid, set_initial_state, reset_bankid_operation } from "js/redux/actions/auth";
import LoaderDiv from "./LoaderDiv";

const BankId = ({
  t,
  bankIdLoginFailed,
  bankIdLoginSuccess,
  login_bankid,
  init_bankid,
  reset_bankid_operation,
  set_initial_state,
  bankIdOperationId,
  bankIdOperationUrl,
  locale,
  region,
}) => {
  let navigate = useNavigate();
  const [bankIdRequestInProgress, setBankIdRequestInProgress] = useState(false);
  const newWindow = useRef(window);

  useEffect(() => {
    set_initial_state();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const addMessageEventListener = async (e) => {
      // this is called by /bankid-return when we return from bankID
      if (e.data.event === "bankid-finished") {
        if (e.data.bankIdOperationId) {
          await login_bankid({
            locale: locale,
            region: region,
            operationId: e.data.bankIdOperationId,
            register: false,
          });
          window.removeEventListener("message", addMessageEventListener);
        }
      }
    };
    if (locale !== null && region !== null) {
      window.addEventListener("message", addMessageEventListener);
    }
  }, [locale, region, login_bankid]);

  useEffect(() => {
    if (bankIdLoginSuccess) {
      navigate("/dashboard/consultations");
    }
  }, [bankIdLoginSuccess, navigate]);

  useEffect(() => {
    if (bankIdOperationId && bankIdOperationUrl && locale === "sv") {
      const openNewTab = async () => {
        newWindow.current.location = bankIdOperationUrl;
        newWindow.current.focus();
        var checkWindowClose = setInterval(async () => {
          if (newWindow.current.closed) {
            clearInterval(checkWindowClose);
            reset_bankid_operation();
          }
        }, 1000);
      };
      openNewTab();
    }
  }, [bankIdOperationId, bankIdOperationUrl, locale, reset_bankid_operation]);

  const bankIdButtonClick = async () => {
    setBankIdRequestInProgress(true);
    let provider;
    if (locale === "sv") {
      newWindow.current = window.open("", "_blank");
    }
    provider = getProviderForBankId(locale);
    try {
      init_bankid({ provider: provider, region: region, requestFrom: "dashboard" });
    } catch (error) {
      console.log(error);
    } finally {
      setBankIdRequestInProgress(false);
    }
  };

  const resetBankIdFailedState = async () => {
    await set_initial_state();
  };

  return (
    <React.Fragment>
      <div className="bankid-login">
        {bankIdLoginFailed ? (
          <div className="bankid-login__error">
            <p>
              {t("bankid.user_found")} (
              <span className="bankid-login__tryagain" onClick={() => resetBankIdFailedState()}>
                {t("bankid.try_again")}
              </span>
              )
            </p>
          </div>
        ) : (
          <React.Fragment>
            {bankIdOperationId ? (
              locale !== "sv" ? (
                <iframe title="bankid" src={bankIdOperationUrl}></iframe>
              ) : (
                <div className="result__processing">
                  <LoaderDiv />
                </div>
              )
            ) : (
              <div className="options-list">
                <button disabled={bankIdRequestInProgress} onClick={() => bankIdButtonClick()} className="dd-button-light">
                  {t("bankid.login")}
                </button>
                {locale === "nb" ? <PhoneLogin></PhoneLogin> : null}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    bankIdOperationUrl: state.auth.bankIdOperationUrl,
    bankIdOperationId: state.auth.bankIdOperationId,
    region: state.auth.region,
    locale: state.auth.locale,
    bankIdLoginFailed: state.auth.bankIdLoginFailed,
    bankIdLoginSuccess: state.auth.bankIdLoginSuccess,
  };
}

export default connect(mapStateToProps, {
  init_bankid,
  login_bankid,
  set_initial_state,
  reset_bankid_operation,
})(withTranslation()(BankId));
