import { GET_DIAGNOSES, GET_CONSULTATION, UPDATE_DOCTORS_DATA } from "js/redux/type.js";

import { axiosAuth } from "js/config/axios-instance";

export function getDiagnoses(succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.get(process.env.REACT_APP_API_BASE_URL + "/cases");
      await dispatch({
        type: GET_DIAGNOSES,
        payload: response.data,
      });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function getDiagnosisByUUID(uuid, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.get(process.env.REACT_APP_API_BASE_URL + `/cases/${uuid}`);
      await dispatch({
        type: GET_CONSULTATION,
        payload: response.data,
      });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function uploadPicture(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const formData = new FormData();
      formData.append("image", data);
      let response = await axiosAuth.post(process.env.REACT_APP_API_BASE_URL + "/upload", formData);
      succcessCallback && succcessCallback();
      return response;
    } catch (e) {
      console.error("Error:", e);
      //   await dispatch({
      //     // type: AUTHENTICATION_FAILED
      //   });
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function reUploadImages(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      await axiosAuth.post(process.env.REACT_APP_API_BASE_URL + "/images", data);
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function followup(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      await axiosAuth.post(process.env.REACT_APP_API_BASE_URL + "/second", data);
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function getDoctor(uuid, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.get(process.env.REACT_APP_API_BASE_URL + `/doctors/${uuid}`);
      await dispatch({
        type: UPDATE_DOCTORS_DATA,
        payload: { [uuid]: response.data },
      });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}
