import React from "react";
import ImageViewer from "./ImageViewer";
import { withTranslation } from "react-i18next";
import { getDateFormat } from "js/HelperMethods";

function UploadedView({ closeup, overview, t, i18n }) {
  return (
    <div className="uploaded-view">
      <strong>{t("summary.re_uploaded_photos")}</strong>
      <div className="images-div">
        <div className="image-div">
          <ImageViewer imageObj={closeup} />
        </div>
        <div className="image-div">
          <ImageViewer imageObj={overview} />
        </div>
      </div>
      <span className="card-timestamp">{getDateFormat(closeup.updated_at, i18n.language, true)}</span>
    </div>
  );
}

export default withTranslation()(UploadedView);
