import {
  SET_INITIAL_STATE,
  RESET_BANKID_OPERATION,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOG_OUT,
  INIT_BANKID,
  LOGIN_BANKID_SUCCESS,
  LOGIN_BANKID_FAILED,
  AUTHENTICATION_FAILED,
  GET_USER_DATA,
} from "js/redux/type.js";

const initialState = {
  region: null,
  locale: null,
  bankIdOperationUrl: null,
  bankIdOperationId: null,
  bankIdLoginSuccess: false,
  loginSuccess: false,
  bankIdLoginFailed: false,
  token: null,
  user_uuid: null,
  userData: null,
};

const appointment = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return {
        ...state,
        region: process.env.REACT_APP_COUNTRY,
        locale: process.env.REACT_APP_LOCALE,
        bankIdOperationUrl: null,
        bankIdOperationId: null,
        bankIdLoginSuccess: false,
        loginSuccess: false,
        bankIdLoginFailed: false,
      };
    case RESET_BANKID_OPERATION:
      return {
        ...state,
        bankIdOperationUrl: null,
        bankIdOperationId: null,
      };
    case INIT_BANKID:
      return {
        ...state,
        bankIdOperationUrl: action.payload.operationUrl,
        bankIdOperationId: action.payload.operationId,
      };
    case LOGIN_BANKID_SUCCESS:
      return {
        ...state,
        bankIdLoginSuccess: true,
        token: action.payload.token,
        user_uuid: action.payload.uuid,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
        token: action.payload.token,
        user_uuid: action.payload.uuid,
      };
    case LOGIN_BANKID_FAILED:
      return {
        bankIdLoginFailed: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loginSuccess: false,
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        loginSuccess: false,
        bankIdLoginFailed: true,
        bankIdLoginSuccess: false,
      };
    case GET_USER_DATA:
      return {
        ...state,
        userData: action.payload.user,
      };
    case LOG_OUT:
      return {
        ...state,
        region: process.env.REACT_APP_COUNTRY,
        locale: process.env.REACT_APP_LOCALE,
        bankIdOperationUrl: null,
        bankIdOperationId: null,
        bankIdLoginSuccess: false,
        loginSuccess: false,
        bankIdLoginFailed: false,
      };
    default:
      return state;
  }
};

export default appointment;
