import React, { useEffect, useState } from "react";
import presignedUrl from "js/config/s3Config";
import LoaderDiv from "./LoaderDiv";
import download from "downloadjs";
import axios from "axios";

function DownloadFile({ uuid, suffix, fileName }) {
  const [file, setFile] = useState(null);
  useEffect(() => {
    (async () => {
      if (uuid) {
        const file = await presignedUrl(`${uuid}.${suffix}`, "referrals/");
        setFile(file);
      }
    })();
  }, [uuid, suffix]);

  const downloadOnClick = async () => {
    const fileObj = await axios.get(file);
    download(fileObj.data, fileName);
  };

  return file === null ? (
    <LoaderDiv />
  ) : (
    <button className="download-file" onClick={downloadOnClick}>
      <span className="file-name">{fileName}</span>
    </button>
  );
}

export default DownloadFile;
