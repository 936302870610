import { reUploadImages, uploadPicture, getDiagnosisByUUID } from "js/redux/actions/diagnoses";
import React, { useState } from "react";
import ImageUpload from "./ImageUpload";
import useModal from "./useModal";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LoaderDiv from "./LoaderDiv";

import { ToastContainer, toast } from "react-toastify";

function ReUpload({ message, uploadPicture, reUploadImages, getDiagnosisByUUID, t }) {
  const [Modal, openModal, closeModal] = useModal();
  const [closeup, setCloseup] = useState(null);
  const [overview, setOverview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { consultationId } = useParams();

  const notify = () => toast.info("Images has been uploaded");

  const reUpload = async () => {
    setUploading(true);
    const closeupResponse = await uploadPicture(closeup);
    const overviewResponse = await uploadPicture(overview);
    await reUploadImages({ closeup: closeupResponse.data.image, overview: overviewResponse.data.image, uuid: consultationId });
    setUploading(false);
    await getDiagnosisByUUID(consultationId);
    closeModal();
    notify();
  };

  const reUploadModal = () => {
    const closeupURL = closeup && URL.createObjectURL(closeup);
    const overviewURL = overview && URL.createObjectURL(overview);
    return (
      <Modal>
        <div className="image-upload-div">
          <div className="modal-header">
            <h1>{t("summary.re_upload_photos")}</h1>
            <h3>{t("summary.re_upload_photos_sub")}</h3>
          </div>
          <div className="image-group">
            <div>
              <h3>{t("summary.need_closeup")}</h3>
              <ImageUpload image={closeupURL} setImage={setCloseup} />
            </div>
            <div>
              <h3>{t("summary.then_overview")}</h3>
              <ImageUpload image={overviewURL} setImage={setOverview} />
            </div>
          </div>
          <button className="confirm-button" disabled={!closeup || !overview || uploading} onClick={reUpload}>
            {uploading ? <LoaderDiv /> : <h3>{t("summary.reupload_proceed")}</h3>}
          </button>
        </div>
      </Modal>
    );
  };
  return (
    <>
      <ToastContainer position="bottom-center" />
      <div className="re-upload">
        <div className="message-div">
          <strong>{t("summary.message_from_doctor")}</strong>
          <span className="text-message">{message}</span>
        </div>
        <button onClick={openModal} className="upload-button">
          {t("summary.re_upload")}
        </button>
      </div>
      {reUploadModal()}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  uploadPicture,
  reUploadImages,
  getDiagnosisByUUID,
})(withTranslation()(ReUpload));
