import { login_signicat } from "js/redux/actions/auth";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

function SignicatReturn({ login_signicat, t }) {
  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  useEffect(
    () => {
      try {
        (async () => {
          const token = searchParams.get("token");
          const userId = searchParams.get("uuid");

          if (searchParams.get("error")) {
            setError(searchParams.get("error_description"));
          } else {
            await login_signicat({
              token: token,
              user_uuid: userId,
            });
          }
          navigate("/dashboard/consultations");
        })();
      } catch (err) {
        console.error("error", err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetBankIdFailedState = () => {
    navigate("/login");
  }

  return error !== "" ? (
    <div>SignicatReturn</div>
  ) : (
    <div className="bankid-login__error">
      <p>
        {t("bankid.user_found")} (
        <span className="bankid-login__tryagain" onClick={() => resetBankIdFailedState()}>
          {t("bankid.try_again")}
        </span>
        )
      </p>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {
  login_signicat,
})(withTranslation()(SignicatReturn));
