import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getDoctor } from "js/redux/actions/diagnoses";
import { animate, stagger } from "motion";

import video_logo from "assets/icons/video-icon.svg";
import { getDateFormat } from "js/HelperMethods";
import DoubleImageViewer from "./DoubleImageViewer";

function Consultation({ uuid, diagnosed, charged, category, overviews, closeups, doctor, t, i18n }) {
  const navigate = useNavigate();
  const goToDetailView = () => {
    navigate(`/dashboard/summary/${uuid}`);
  };

  useEffect(() => {
    animate(
      ".consultation",
      {
        transform: ["translateY(-40px)", "translateY(0px)", "none"],
        opacity: [0, 1],
      },
      { delay: stagger(0.05) }
    );
  }, []);

  const prepareImageObj = (closeups, overviews) => {
    const closeupLength = closeups.length;
    const overviewLength = overviews.length;
    let imageObj = { latest: {}, others: [] };
    const maxLen = Math.max(closeupLength, overviewLength);
    for (let i = maxLen - 1; i >= 0; i--) {
      if (i === maxLen - 1) {
        imageObj.latest = {
          closeup: closeups[i],
          overview: overviews[i],
        };
      } else {
        imageObj.others = [{ closeup: closeups[i], overview: overviews[i] }, ...imageObj.others];
      }
    }
    return imageObj;
  };

  return (
    <div className="consultation" style={{ opacity: 0 }}>
      {
        <div className="image-div">
          {category === "video" ? (
            <img src={video_logo} alt="" />
          ) : (
            overviews.length > 0 && <DoubleImageViewer imageObj={prepareImageObj(closeups, overviews)} />
          )}
        </div>
      }
      {diagnosed ? (
        <>
          <div data-title={`${t("case.assigned_to")}:`} className="dr-name">
            <span>
              {doctor && (
                <>
                  <span>{t("case.assigned_to")}</span> <strong>Dr {doctor[doctor.length - 1].display_name}</strong>
                </>
              )}
            </span>
          </div>
        </>
      ) : (
        <span data-title={`${t("case.assigned_to")}:`} className="dr-name">
          <span>{t("dashboard.state.inprogress")}</span>{" "}
        </span>
      )}

      <span data-title={`${t("case.booked")}:`} className="date">
        {getDateFormat(charged, i18n.language)}
      </span>
      <span data-title={`${t("case.consultation_type")}:`} className="category">
        {t(`dashboard.flow.${category}`)}
      </span>
      <button className="view-button" onClick={goToDetailView}>
        <strong>{t("dashboard.action.view")}</strong>
      </button>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    doctors: state.diagnoses.doctors,
  };
}

export default connect(mapStateToProps, {
  getDoctor,
})(withTranslation()(Consultation));
