import React, { useState } from "react";
import { axiosNoAuth } from "js/config/axios-instance";
import LoaderDiv from "./LoaderDiv";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

function BankIdSignicat({ t }) {
  const [loading, setLoading] = useState(false);

  const loginBankid = () => {
    try {
      // Make a request to Laravel backend to initiate Signicat authentication
      (async () => {
        setLoading(true);
        const response = await axiosNoAuth.get(process.env.REACT_APP_API_BASE_URL + "/signicat/authenticate?register=false&redirect=dashboard");

        // Redirect user to Signicat for authentication
        if (response.status === 200) {
          // Redirect the user to the Signicat authentication URL
          const { redirectUrl } = response.data;
          // console.log("redirectUrl",redirectUrl);
          window.location.href = redirectUrl;
        } else {
          // Handle error
          console.error("Failed to initiate Signicat authentication");
        }
      })();
      // window.location.href = response.data.redirect_url;
    } catch (error) {
      // Handle error
      console.error("Error initiating Signicat authentication:", error);
    }
  };
  return (
    <div className="bankid-login signicat">
      {loading ? (
        <LoaderDiv />
      ) : (
        <div className="options-list">
          <button onClick={() => loginBankid()} className="dd-button-light">
            {t("bankid.login")}
          </button>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(withTranslation()(BankIdSignicat));
