import {
  SET_INITIAL_STATE,
  LOGIN_SUCCESS,
  AUTHENTICATION_FAILED,
  LOGIN_FAILED,
  LOG_OUT,
  INIT_BANKID,
  LOGIN_BANKID_SUCCESS,
  LOGIN_BANKID_FAILED,
  RESET_BANKID_OPERATION,
} from "js/redux/type.js";

import { axiosNoAuth, axiosAuth } from "js/config/axios-instance";

export function set_initial_state(succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      await dispatch({
        type: SET_INITIAL_STATE,
      });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error(e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function init_bankid(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosNoAuth.post(process.env.REACT_APP_API_BASE_URL + "/init-bankid", data);
      await dispatch({
        type: INIT_BANKID,
        payload: response.data,
      });
      succcessCallback && succcessCallback();
      return response;
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function reset_bankid_operation(succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      await dispatch({
        type: RESET_BANKID_OPERATION,
      });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function login_bankid(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosNoAuth.post(process.env.REACT_APP_API_BASE_URL + "/login-bankid", data);
      if (response.data.state === "STATE_COMPLETED") {
        await dispatch({
          type: LOGIN_BANKID_SUCCESS,
          payload: response.data,
        });
      } else {
        await dispatch({
          type: LOGIN_BANKID_FAILED,
          payload: response.data,
        });
      }
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}


export function login_signicat(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
        await dispatch({
          type: LOGIN_BANKID_SUCCESS,
          payload: data,
        });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function emailLogin(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosNoAuth.post(process.env.REACT_APP_API_BASE_URL + "/login", data);
      if (response.data.token) {
        await dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
      } else {
        await dispatch({
          type: LOGIN_FAILED,
          payload: response.data,
        });
      }
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      // await dispatch({
      //   // type: AUTHENTICATION_FAILED
      // });
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function otpVerify(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.post(process.env.REACT_APP_API_BASE_URL + `/verify/${data}`);
      // if (response.data.token) {
      //   await dispatch({
      //     type: LOGIN_SUCCESS,
      //     payload: response.data,
      //   });
      // } else {
      //   await dispatch({
      //     type: LOGIN_FAILED,
      //     payload: response.data,
      //   });
      // }
      succcessCallback && succcessCallback();
      return response.data;
    } catch (e) {
      console.error("Error:", e);
      await dispatch({
        type: AUTHENTICATION_FAILED,
      });
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function changePassword(data, succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.post(process.env.REACT_APP_API_BASE_URL + "/change-password", data);
      succcessCallback && succcessCallback();
      return response.data;
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}

export function logOut(data, succcessCallback, errorCallback) {
  return function (dispatch) {
    try {
      dispatch({
        type: LOG_OUT,
      });
      localStorage.clear();
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}
