import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

function BankIdReturn({ locale, bankIdOperationId }) {
  useEffect(() => {
    if (locale !== "sv") {
      window.parent.postMessage({ event: "bankid-finished", bankIdOperationId: bankIdOperationId }, "*");
    } else {
      setTimeout(function () {
        window.opener.postMessage({ event: "bankid-finished", bankIdOperationId: bankIdOperationId }, "*");
        window.opener = null;
        window.open("", "_self");
        window.close();
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="bankid-login__return">Din BankID er verifisert</div>;
}

function mapStateToProps(state) {
  return {
    locale: state.auth.locale,
    bankIdOperationId: state.auth.bankIdOperationId,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(BankIdReturn));
