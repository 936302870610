import Header from "js/views/components/Header";
import React from "react";
import { Outlet } from "react-router-dom";

function Dashboard() {
  return (
    <React.Fragment>
      <Header />
      <div className="container">
        <Outlet />
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
