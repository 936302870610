import React, { useRef, useState } from "react";
import arrowDown from "assets/icons/arrow-down.svg";

function Dropdown({ options, userName }) {
  const [showOptions, setShowOptions] = useState(false);
  const contentRef = useRef();
  const optionsDiv = () => (
    <div style={{ visibility: showOptions ? "unset" : "hidden" }} ref={contentRef} className="options">
      {options}
    </div>
  );
  return (
    <div className="dropdown" 
      onClick={() => {
        setShowOptions((showOptions) => !showOptions);
      }}
    >
      <span className="name" >{userName}</span>
      <img
        src={arrowDown}
        alt="down-arrow"
      />
      {optionsDiv()}
    </div>
  );
}

export default Dropdown;
