import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import translationNO from "locales/nb.json";
import translationSE from "locales/se.json";

const resources = {
  no: {
    translations: translationNO,
  },
  se: {
    translations: translationSE,
  },
  sv: {
    translations: translationSE,
  },
  nb: {
    translations: translationNO,
  },
}

i18n.use(initReactI18next).init({
  // we init with resources
  resources,
  fallbackLng: "nb",
  debug: true,
  lng: process.env.REACT_APP_LOCALE,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  // keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
