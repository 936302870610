import Consultation from "js/views/components/Consultation";
import DDButton from "js/views/components/DDButton";
import React, { useEffect, useState } from "react";
import { animate } from "motion";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { getDiagnoses } from "js/redux/actions/diagnoses";
import LoaderDiv from "../components/LoaderDiv";
import VerticalLine from "../components/VerticalLine";

import { ToastContainer, toast } from "react-toastify";

function Consultations({ consultations, getDiagnoses, region, t }) {
  const [prevConsultations, setPrevConsultations] = useState([]);
  const [liveConsultations, setLiveConsultations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const notify = () => toast.error("Something unexpected has occurred!");

  const getSetDiagnoses = async () => {
    setLoading(true);
    setError(false);
    await getDiagnoses(
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
        notify();
      }
    );
  };

  const redirect = () => {
    window.location.replace(process.env.REACT_APP_CHECK_URL);
  };

  useEffect(() => {
    getSetDiagnoses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (consultations.length > 0) {
      const sortConsultations = (consultations) => {
        let prev = [];
        let live = [];
        consultations.forEach((consultation) => {
          if (consultation.diagnosed === 1) prev.push(consultation);
          else live.push(consultation);
        });
        //Sort prev & live consultations before storing in state
        prev.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1);
        live.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);
        setPrevConsultations(prev);
        setLiveConsultations(live);
      };

      sortConsultations(consultations);
      animate(".consultations .consultation-header, h2 ", { opacity: [0, 1] }, { duration: 0.8, easing: "ease-out" });
    }
  }, [consultations]);

  const buildConsultations = (list) => {
    return list.map((item, index) => (
      <li className="consultation-li" key={index}>
        <Consultation {...item} />
      </li>
    ));
  };
  return error ? (
    <div className="consultations">
      <div className="error-div">
        <span> {t("errors.something_unexpected")}</span>
        <button onClick={getSetDiagnoses}>{t("errors.retry")}</button>
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  ) : (
    <div className="consultations">
      <div className="consultation-header">
        <h1>{t("dashboard.heading")}</h1>
        <DDButton onClick={redirect} className="add-button">
          {t("dashboard.action.new")}
        </DDButton>
      </div>
      <VerticalLine />
      <section className="consultation-block">
        <h2>{t("dashboard.consultations.live")}</h2>
        {loading ? (
          <LoaderDiv />
        ) : liveConsultations.length < 1 ? (
          <ul className="consultations-list">
            <span className="text-muted text-div-container">{t("dashboard.consultations.no_live")}</span>
          </ul>
        ) : (
          <ul className="consultations-list">{buildConsultations(liveConsultations)}</ul>
        )}
      </section>
      <section className="consultation-block prev">
        <h2>{t("dashboard.consultations.previous")}</h2>
        {loading ? (
          <LoaderDiv />
        ) : prevConsultations.length < 1 ? (
          <ul className="consultations-list">
            <span className="text-muted text-div-container">{t("dashboard.consultations.no_prev")}</span>
          </ul>
        ) : (
          <ul className="consultations-list">{buildConsultations(prevConsultations)}</ul>
        )}
      </section>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    bankIdOperationId: state.auth.bankIdOperationId,
    region: state.auth.region,
    locale: state.auth.locale,
    consultations: state.diagnoses.consultations,
  };
}

export default connect(mapStateToProps, {
  getDiagnoses,
})(withTranslation()(Consultations));
