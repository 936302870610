import { axiosAuth } from "js/config/axios-instance";
import { GET_USER_DATA } from "js/redux/type.js";

export function getUser(succcessCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.get(process.env.REACT_APP_API_BASE_URL + "/user");
      await dispatch({
        type: GET_USER_DATA,
        payload: response.data,
      });
      succcessCallback && succcessCallback();
    } catch (e) {
      console.error("Error:", e);
      errorCallback && errorCallback((e, (_) => _.response.data.message));
    }
  };
}
