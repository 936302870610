import React, { useEffect, useState } from "react";
import se_logo from "assets/dd-logo-dark.png";
import no_logo from "assets/Logos.svg";
import ProfileDropdown from "./ProfileDropdown";

const logos = {
  no: no_logo,
  se: se_logo,
};

function Header() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(window.location.pathname !== "/login-email" && window.location.pathname !== "/login");
  }, []);

  return (
    <div className="header">
      <div className="container">
        <div className="flex-div">
          <img className={`logo logo-${process.env.REACT_APP_COUNTRY}`} src={logos[process.env.REACT_APP_COUNTRY]} alt="logo"></img>
          {show && <ProfileDropdown />}
        </div>
      </div>
    </div>
  );
}

export default Header;
