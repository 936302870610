import axios from 'axios';

async function presignedUrl (key, folder) {
    try {
        const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/getImage', {
            uuid: key,
            folder: folder
        });

        return response.data.url;
    } catch (err) {
        console.log('err', err);
    }
}

export default presignedUrl;

