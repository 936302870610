import React from "react";
import ImageViewer from "./ImageViewer";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getDateFormat } from "js/HelperMethods";

function CaseDetails({ duration, description, body_part, overviews, closeups, created_at, t, category, i18n }) {
  const text = (strings, ...values) => {
    const value = values[0];
    try {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === "object")
        return parsedValue
          .map((value) => {
            if (value.custom) return value.custom;
            else return value;
          })
          .join(", ");
      else return parsedValue;
    } catch (err) {
      return value;
    }
  };
  return (
    <div className="case-details">
      <strong>{t("case.title")}</strong>
      <section className="row">
        <div>{t("case.condition")}</div>
        <div>{text`${description}`}</div>
      </section>
      {category !== "video" ? (
        <>
          <section className="row">
            <div>{t("case.since")}</div>
            <div>{duration}</div>
          </section>
          <section className="row">
            <div>{t("case.body_part")}</div>
            <div>{text`${body_part}`}</div>
          </section>
          <section className="row">
            <div className="image-div">
              <ImageViewer imageObj={closeups[0]} />
            </div>
            <div className="image-div">
              <ImageViewer imageObj={overviews[0]} />
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
      <span className="card-timestamp">{getDateFormat(created_at, i18n.language, true)}</span>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(withTranslation()(CaseDetails));
