import { combineReducers } from "@reduxjs/toolkit";
import auth from "js/redux/reducers/auth";
import diagnoses from "js/redux/reducers/diagnoses";

const appReducer = combineReducers({
  auth,
  diagnoses,
});

export default appReducer;
