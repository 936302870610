import React from "react";
import ImageViewer from "./ImageViewer";
// import person from "assets/icons/profile-person.svg";

function DocProfile({ display_name, profession, display_image }) {
  return (
    <div className="doc-profile">
      <div className="image">{display_image && <ImageViewer image={display_image} showPopup={false} />}</div>
      <div className="col">
        <h3>Dr {display_name}</h3>
        {profession && <span>{profession}</span>}
      </div>
    </div>
  );
}

export default DocProfile;
