import AskQueries from "js/views/components/AskQueries";
import CaseDetails from "js/views/components/CaseDetails";
import CaseReport from "js/views/components/CaseReport";
import DocProfile from "js/views/components/DocProfile";
import ReUpload from "js/views/components/ReUpload";
import UploadedView from "js/views/components/UploadedView";
import VerticalLine from "js/views/components/VerticalLine";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { getDiagnosisByUUID, getDoctor } from "js/redux/actions/diagnoses";
import LoaderDiv from "../components/LoaderDiv";
import { animate } from "motion";
import Collapse from "../components/Collapse";

function Summary({ consultation, getDiagnosisByUUID, getDoctor, doctors, t }) {
  const { consultationId } = useParams();

  const [loading, setLoading] = useState(true);
  const [doctor, setDoctor] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getDiagnosisByUUID(consultationId, () => {
        setLoading(false);
      });
      animate(".summary ", { opacity: [0, 1] }, { duration: 0.8, easing: "ease-out" });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(consultation).length > 0)
      (async () => {
        if(consultation.diagnosed){
          const drUUID = consultation.diagnoses[consultation.diagnoses.length - 1].performed_by;
          await getDoctor(drUUID);
          setDoctor(doctors[drUUID].doctor[0]);
        }
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation]);

  const rejectedState = (consultation) => {
    if (Object.keys(consultation) < 1) return;
    if (consultation.rejected_by !== null) return "NOT_UPLOADED";
    else {
      if (consultation.closeups.length > 1 && consultation.overviews.length > 1) return "UPLOADED";
      else return "NOT_REJECTED";
    }
  };

  const askQueryDiv = (diagnoses) => (
    <div className="ask-query-div">
      <Collapse
        defaultShow={diagnoses[diagnoses.length - 1].second_opinion_reason !== null}
        headerShowComponent={<span>{t("summary.any_question")}</span>}
        headerHideComponent={<span>{t("summary.any_question")}</span>}>
        <AskQueries diagnoses={diagnoses} referrals={consultation.referrals} />
      </Collapse>
    </div>
  );
  const caseReport = () => {
    const diag = consultation.diagnoses[consultation.diagnoses.length - 1];
    return (
      consultation.diagnoses.length > 0 && <CaseReport {...diag} referrals={consultation.referrals.filter((referral) => referral.diagnosis === diag.uuid)} />
    );
  };
  const uploadedDiv = () =>
    rejectedState(consultation) === "UPLOADED" && (
      <UploadedView closeup={consultation.closeups[consultation.closeups.length - 1]} overview={consultation.overviews[consultation.overviews.length - 1]} />
    );
  const uploadDiv = () => rejectedState(consultation) === "NOT_UPLOADED" && <ReUpload message={consultation.reject_reason} />;
  const caseDetailsDiv = () => <CaseDetails {...consultation} />;

  const hasSecondOpinion = (diagnoses) => {
    if (diagnoses.length === 0) return false
    else return (diagnoses[diagnoses.length - 1].second_opinion_reason !== null);
  };

  const showQuery = (consultation) => {
    return hasSecondOpinion(consultation.diagnoses);
  };

  const queryDiv = () => {
    return showQuery(consultation) && askQueryDiv(consultation.diagnoses);
  };

  const verticalLine = () => {
    if (consultation.diagnoses.length > 0) return <VerticalLine />;
    else return false;
  };

  const buildSummaryContent = () => {
    const divListReport = [caseReport(), queryDiv(), verticalLine()];
    const divListHistory = [uploadedDiv(), uploadDiv(), caseDetailsDiv()];
    const filteredDivListReport = divListReport.filter((div) => {
      if (div) return div;
      else return false;
    });
    const filteredDivListHistory = divListHistory.filter((div) => {
      if (div) return div;
      else return false;
    });
    const historyCollapse = (
      <Collapse
        defaultShow={!consultation.diagnosed}
        headerShowComponent={<strong>{t("summary.show_case_history")}</strong>}
        headerHideComponent={<strong>{t("summary.hide_case_history")}</strong>}>
        <div className="history-div">{filteredDivListHistory}</div>
      </Collapse>
    );
    return [...filteredDivListReport, historyCollapse];
  };

  const feedbackButton = () => {
    if (consultation.category === "video" && process.env.REACT_APP_FEEDBACK_VIDEO)
      return (
        <a target="_blank" rel="noreferrer noopener" href={process.env.REACT_APP_FEEDBACK_VIDEO} className="redirect-feedback">
          <span>{t("case.give_feedback")}</span>
        </a>
      );
    else if (consultation.category === "picture" && process.env.REACT_APP_FEEDBACK_PICTURE)
      return (
        <a target="_blank" rel="noreferrer noopener" href={process.env.REACT_APP_FEEDBACK_PICTURE} className="redirect-feedback">
          <span>{t("case.give_feedback")}</span>
        </a>
      );
    else return <></>;
  };

  const returnDiv = () => (
    <div className="summary">
      <div className="summary-header">
        <div className="back-header">
          <button
            onClick={() => {
              navigate("/dashboard/consultations");
            }}
            className="back"
          />
          <h1>{t("case.summary")}</h1>
        </div>
        <div className="feedback-button">{consultation.diagnosed === 1 && feedbackButton()}</div>
      </div>
      {loading ? (
        <LoaderDiv />
      ) : (
        <>
          {doctor && (
            <div className="doc-header">
              <DocProfile {...doctor} />
              <div className="feedback-button">{consultation.diagnosed === 1 && feedbackButton()}</div>
            </div>
          )}
          <div className="summary-col">
            <div className="summary-content">{buildSummaryContent()}</div>
          </div>
        </>
      )}
    </div>
  );

  return returnDiv();
}

function mapStateToProps(state) {
  return {
    consultation: state.diagnoses.selectedConsultation,
    doctors: state.diagnoses.doctors,
  };
}

export default connect(mapStateToProps, {
  getDiagnosisByUUID,
  getDoctor,
})(withTranslation()(Summary));
