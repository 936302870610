import React, { useState, useReducer, useCallback, useEffect } from "react";
import presignedUrl from "js/config/s3Config";
import { animate } from "motion";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Collapse from "./Collapse";
import LoaderDiv from "./LoaderDiv";

function DoubleImageViewer({ imageObj, showPopup = true, t }) {
  const [show, setShow] = useState(false);
  const [sources, setSources] = useState(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (imageObj) {
        let imageSrcObj = { latest: {}, others: [] };
        // const latestCloseup = imageObj.latest.closeup;
        const latestOverview = imageObj.latest.overview;
        imageSrcObj.latest = {
          // closeup: await presignedUrl(`${latestCloseup.uuid}.${latestCloseup.suffix}`, "uploads/"),
          overview: await presignedUrl(`${latestOverview.uuid}.${latestOverview.suffix}`, "uploads/"),
        };
        // imageObj.others.forEach(async (imageObjInstance) => {
        //   const otherCloseup = imageObjInstance.closeup;
        //   const otherOverview = imageObjInstance.overview;
        //   imageSrcObj.others = [
        //     {
        //       closeup: await presignedUrl(`${otherCloseup.uuid}.${otherCloseup.suffix}`, "uploads/"),
        //       overview: await presignedUrl(`${otherOverview.uuid}.${otherOverview.suffix}`, "uploads/"),
        //     },
        //     ...imageSrcObj.others,
        //   ];
        // });
        setSources(imageSrcObj);
      }
    })();
  }, [imageObj]);

  useEffect(() => {
    (async () => {
      if (imageObj && show && !allImagesLoaded) {
        let imageSrcObj = sources;
        const latestCloseup = imageObj.latest.closeup;
        const latestOverview = imageObj.latest.overview;
        imageSrcObj.latest = {
          closeup: await presignedUrl(`${latestCloseup.uuid}.${latestCloseup.suffix}`, "uploads/"),
          overview: await presignedUrl(`${latestOverview.uuid}.${latestOverview.suffix}`, "uploads/"),
        };
        for (let i = 0; i < imageObj.others.length; i++) {
          const imageObjInstance = imageObj.others[i];

          const otherCloseup = imageObjInstance.closeup;
          const otherOverview = imageObjInstance.overview;
          imageSrcObj.others = [
            {
              closeup: await presignedUrl(`${otherCloseup.uuid}.${otherCloseup.suffix}`, "uploads/"),
              overview: await presignedUrl(`${otherOverview.uuid}.${otherOverview.suffix}`, "uploads/"),
            },
            ...imageSrcObj.others,
          ];
        }
        setSources(imageSrcObj);
        setAllImagesLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageObj, show]);

  const closeOverlay = () => {
    setShow(false);
  };
  const closeOverlayOnTargetClick = (e) => {
    if (e.target === e.currentTarget) setShow(false);
  };
  const openOverlay = () => {
    setShow(true);
  };

  const readyForOverlay = (sources) => {
    let ready = true;
    if (!sources?.latest.closeup || !sources?.latest.overview) {
      ready = false;
    }
    return ready;
  };

  const ImageOverlay = () => {
    const [isMounted] = useReducer((p) => !p, true);
    const handleMount = useCallback((e) => {
      const overlay = e;
      if (overlay) {
        const scroll = window.scrollY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${scroll}px`;
        overlay.style.top = `${overlay.offsetTop + scroll}px`;
        animate(".image-overlay", {
          opacity: [0, 1],
        });
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    }, []);

    return (
      <>
        {isMounted && (
          <div ref={handleMount} className="image-overlay" onClick={closeOverlayOnTargetClick}>
            {readyForOverlay(sources) ? (
              <div className="image-container-double">
                <div className="header-div">
                  <span>{t("components.double-image-viewer.your-uploads")}</span>
                  <div onClick={closeOverlay} className="cross-button" />
                </div>
                <div className="images-div">
                  <img className="image" src={sources.latest.closeup} alt="img" />
                  <img className="image" src={sources.latest.overview} alt="img" />
                </div>
                {sources.others.length > 0 && (
                  <Collapse
                    headerShowComponent={<span>{t("components.double-image-viewer.older-images")}</span>}
                    headerHideComponent={<span>{t("components.double-image-viewer.older-images")}</span>}>
                    {sources.others.map((imgObj) => {
                      return (
                        <div className="older-images-div">
                          <img className="image" src={imgObj.closeup} alt="img" />
                          <img className="image" src={imgObj.overview} alt="img" />
                        </div>
                      );
                    })}
                  </Collapse>
                )}
              </div>
            ) : (
              <LoaderDiv />
            )}
          </div>
        )}
      </>
    );
  };

  const ImageView = () => (
    <div className="image-viewer">
      {sources?.latest.overview && <img className="thumbnail" src={sources.latest.overview} alt="img" width="100%" height="100%" />}
      {showPopup && sources && (
        <div onClick={openOverlay} className="overlay">
          <span className="view-button">{t("image.view")}</span>
        </div>
      )}
    </div>
  );

  return (
    <>
      {ImageView()}
      {show && <ImageOverlay />}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(withTranslation()(DoubleImageViewer));
