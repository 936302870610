export const SET_INITIAL_STATE     = "set_initial_state";
export const INIT_BANKID           = "init_bankid";
export const LOGIN_BANKID_SUCCESS  = "login_bankid_success";
export const LOGIN_SUCCESS         = "login_success";
export const LOGIN_BANKID_FAILED   = "login_bankid_failed";
export const LOGIN_FAILED          = "login_failed";
export const LOG_OUT               = "logout";
export const GET_USER_DATA         = "user_data";
export const UPDATE_DOCTORS_DATA   = "update_doctors_data";
export const RESET_BANKID_OPERATION = "reset_bankid_operation"

export const GET_DIAGNOSES         = "get_diagnoses";
export const GET_CONSULTATION      = "get_consultation";
export const AUTHENTICATION_FAILED = "auth_failed";
