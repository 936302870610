import React from "react";
import DownloadFile from "./DownloadFile";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getDateFormat } from "js/HelperMethods";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

function CaseReport({ description, referrals, userData, created_at, showHeader = true, t, i18n }) {
  const listReferals = (referrals) => referrals.map((referal) => <DownloadFile fileName={referal.name} suffix={referal.suffix} uuid={referal.uuid} />);
  // const name = (strings, ...values) => {
  //   return values.filter((value) => value !== null).join(" ");
  // };
  return (
    <div className="case-report">
      {showHeader && <strong>{t("summary.case_report")}</strong>}
      {/* <span className="name">Hi {name`${userData.firstname} ${userData.lastname}`},</span> */}
      <span className="report"><ReactQuill theme="bubble" value={description} readOnly /></span>
      {listReferals(referrals)}
      <span className="card-timestamp">{getDateFormat(created_at, i18n.language, true)}</span>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userData: state.auth.userData,
  };
}

export default connect(mapStateToProps, {})(withTranslation()(CaseReport));
