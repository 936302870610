import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Header from "js/views/components/Header";
import BankId from "js/views/components/BankId";
import { animate } from "motion";

import { set_initial_state } from "js/redux/actions/auth";
import BankIdSignicat from "../components/BankIdSignicat";

const Login = ({ t, region, set_initial_state }) => {

  useEffect(() => {
    set_initial_state();
    animate(".login *", { opacity: [0, 1] }, { duration: 0.8, easing: "ease-out" });
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="login">
        <div className="login__heading">{t("login.heading")}</div>
        <div className="login__subHeading">{t("login.subheading")}</div>
        {region === 'se' ? <BankId></BankId> : <BankIdSignicat/>}
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    region: state.auth.region,
  };
}

export default connect(mapStateToProps, {
  set_initial_state,
})(withTranslation()(Login));
